/* https://docs.blocknative.com/onboard/core#custom-styling */

:root {
  --onboard-font-family-normal: Poppins;
  --onboard-font-family-semibold: Poppins;
  --onboard-font-family-light: Poppins;

  --onboard-modal-z-index: 1301;
  --onboard-account-select-modal-z-index: 1302;
  --onboard-login-modal-z-index: 1303;
  --onboard-common-modal-index: 1304;
}
